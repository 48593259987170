export const field = [
    {
        id:"deviceType",
        name:"deviceType", 
        label:"Device Type",
        type:"select",
    },
    {
        id:"name",
        name:"name", 
        label:"Device Name",
        type:"input",
    },
    // {
    //     id:"location",
    //     name:"location", 
    //     label:"Location",       
    //     type:"Camera"
    // },
    {
        id:"cameraType",
        name:"cameraType", 
        label:"Camera Type",
        type:"Camera"
    },
    {
        id:"streamUrl",
        name:"streamUrl", 
        label:"Stream URL",
        type:"Camera"
    },
    {
        id:"videoLength",
        name:"videoLength", 
        label:"video Length",
        type:"Camera"
    },
    {
        id:"deviceMake",
        name:"deviceMake", 
        label:"Device Make",
        type:"input",
    },
    // {
    //     id:"status",
    //     name:"status", 
    //     label:"Status",
    //     type:"select",
    // },
    {
        id:"serialNumber",
        name:"serialNumber", 
        label:"Serial Number",
        type:"input",
    },
    {
        id:"dateInstalled",
        name:"dateInstalled", 
        label:"Date Installed",
        type:"datePicker",
    },
    {
        id:"macAddress",
        name:"macAddress", 
        label:"MACAddress",
        type:"input",
    },
    {
        id:"ipAddress",
        name:"ipAddress", 
        label:"IP Address",
        type:"input",
    },
    {
        id:"topicName",
        name:"topicName", 
        label:"Topic Name",
        type:"input",
    },
    
]