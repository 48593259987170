import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../config/apiConfig";
import checkImg from "../../assets/images/check_circle.svg";
import "./MFALogin.css";

const MFALogin = ({ open, onClose }) => {
  const [code, setCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [enableNextBtn, setEnableNextBtn] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleCodeVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(
        !code
          ? "Please enter the verification code."
          : "Please enter a 6-digit code."
      );
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/auth/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: location.state.username,
          code,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.verifyCode === true) {
        setMessage("Verication successful!");
        setErrorMessage("");
        setEnableNextBtn(true);
      } else {
        setErrorMessage("Verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleDone = () => {
    // Mark MFA as completed
    localStorage.setItem("isMFACompleted", "true");
    // Mark user as authenticated
    localStorage.setItem("isAuthenticated", "true");
    // Save the default path
    localStorage.setItem("lastPath", "/dashboard");
    // Dispatch Redux action and navigate
    dispatch(LoginValue(true));
    // navigate("/dashboard", { replace: true });
  };

  return (
    <div className="mfa_verifyCode">
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          onClose();
        }}
        fullWidth
        maxWidth="sm"
        className="dailog_mfa"
        PaperProps={{
          style: {
            position: "fixed",
            // width: "600px",
            // height: "326px",
            top: "25%",
            left: "0",
            right: '0',
            gap: "0px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1,
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "white", // Adjust the background color
            },
          },
        }}
      >
        <div className="dialog-title">
          <h2 className="dialog-title-text">Multi-Factor Authentication</h2>
          <IconButton onClick={onClose} className="icon-container">
            <CloseIcon className="icon" />
          </IconButton>
        </div>

        {!isVerified ? (
          <div className="dialog-content">
            <p>
              Let’s set up Authenticator App. What Authenticator App do you want
              to use?
          </p>
            <div className="authenticator-input">
              <div className="label-text">Authenticator App</div>
              <div className="align">
                <input
                  type="text"
                  className="text-box"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength="6"
                />
                <div className="send-code-button">
                  <Button
                    color="primary"
                    className="btn-text"
                    onClick={handleCodeVerification}
                  >
                    Verify
                </Button>
                </div>
              </div>
              {message && (
                <div style={{ color: "green", marginTop: "10px" }}>{message}</div>
              )}
              {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            </div>
          </div>
        ) : (
            <div className="success-dialog">
              <div className="success-box">
                <img src={checkImg} alt="Success" />
                <h2>Success</h2>
                <p>
                  Your Authenticator App verification is successfully completed.
            </p>
              </div>
            </div>
          )}

        <DialogActions>
          {!isVerified ? (
            <Button
              color="primary"
              className="disabled-button"
              style={{ bottom: "20px", right: "15px" }}
              disabled={!enableNextBtn}
              onClick={() => setIsVerified(true)}
            >
              Next
          </Button>
          ) : (
              <Button
                color="primary"
                className="next-button"
                style={{ top: "40%", right: "15px" }}
                onClick={() => handleDone()}
              >
                Done
          </Button>
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MFALogin;
