import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Box, TextField, InputAdornment, IconButton, styled, Switch, Stack, FormGroup, Snackbar } from '@mui/material';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import errorSign from '../../../assets/images/errorSign.svg';
import checkImg from "../../../assets/images/check_circle.svg";
import { baseUrl } from '../../../config/apiConfig'
import { useNavigate } from "react-router-dom";
import { LoginValue } from "../../../Actions/LoginPageAction";
// import UpdateProfilePhoto from './UpdateProfilePhoto.js';
import ActivityLog from './ActivityLog'
import { jwtDecode } from "jwt-decode";

import '../profile.scss'
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    borderRadius: 16,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'rgba(8, 109, 171, 1)',
                ...theme.applyStyles('dark', {
                    backgroundColor: 'rgba(8, 109, 171, 1)',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: 16,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255,255,255,.35)',
        }),
    },
}));
const Profile = () => {
    const [formData, setFormData] = useState({ newPassword: '', confirmNewPassword: '' });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [classForToast, setClassForToast] = useState("");
    const [passwordStrength, setPasswordStrength] = useState({ length: false, mix: false, special: false });
    const [passwordsMatch, setPasswordsMatch] = useState(true);  // Default to true, as we don't validate until "Save"
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [mfa, setMfa] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Real-time validation for password strength
        if (name === "newPassword") {
            const hasLength = value.length >= 8;
            const hasMix = /[a-z]/.test(value) && /[A-Z]/.test(value);
            const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);
            setPasswordStrength({ length: hasLength, mix: hasMix, special: hasSpecial });
        }
    };

    // Validation function triggered on form submit
    const handleValidation = () => {
        let isValid = true;
        // Check password strength
        if (formData.newPassword === "") {
            setOpen(true)
            setMessage("Please enter your new password.")
            setClassForToast("error")
            isValid = false;
        } else if (!passwordStrength.length || !passwordStrength.mix || !passwordStrength.special) {
            setOpen(true)
            setMessage("Password does not meet strength requirements.")
            setClassForToast("error")
            isValid = false;
        }

        // Check if passwords match
        if (formData.confirmNewPassword === "") {
            setOpen(true)
            setMessage("Please confirm your new password.")
            setClassForToast("error")
            isValid = false;
        } else if (formData.confirmNewPassword !== formData.newPassword) {
            setOpen(true)
            setMessage("Passwords do not match.")
            setClassForToast("error")
            setPasswordsMatch(false);
            isValid = false;
        } else {
            setPasswordsMatch(true);  // If they match, reset the match status
        }

        // setShowError(!isValid);
        return isValid;
    };
    const mfaHandler = (value) => {
        setMfa(value);
    }
    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        let token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        if (!handleValidation()) return;
        try {
            const formDataToSend = {
                email: decodedToken.email,
                newPassword: formData.newPassword, // Adjust the fields accordingly
                reNewPassword: formData.confirmNewPassword
            };
            const url = `${baseUrl}/setPassword`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const token = response.data ?.token;
                setOpen(true)
                setMessage("Password updated successfully.")
                setClassForToast("success")
                setFormData({ newPassword: '', confirmNewPassword: '' });  // Clear form
                if (token) {
                 
                    // setTimeout(() => {
                    //     // Redirect to login page after 2 seconds
                    //     window.location.href = "/login";
                    // }, 2000);
                }
            } else if (response.status === 400) {
                setOpen(true)
                setMessage("Invalid credentials.")
                setClassForToast("error")
            }
        } catch (error) {
            console.error("Error making request:", error);
            setOpen(true)
            setMessage("Invalid credentials.")
            setClassForToast("error")
        }
    };

    const Redirect = () => {
        setFormData({ newPassword: '', confirmNewPassword: '' });  // Clear form
        setOpen(false)
        setMessage("")
        setClassForToast("")
        localStorage.removeItem("token");
        navigate("/");
        dispatch(LoginValue(false));
        window.location.reload();
    }
    const handleClose = () => {
        setOpen(false)
        setMessage("")
        setClassForToast("")
    };

    return (
        <div className="update_pass_mnWrapper">

            <div className="updatePassword_myacc">
                <div className="update_wrapper">
                    {/* {(showError && formError !== "") && (
                        <div className="error_wraperProfile">
                            <img src={errorSign} alt="img" />
                            {formError}
                        </div>
                    )} */}
                    <div className="profile-form">
                        <h2>Update Password</h2>
                        <Box component="form" noValidate autoComplete="off" className="field">
                            <TextField
                                id="newPassword"
                                name="newPassword"
                                label="New Password"
                                type={showNewPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={handleInputChange}
                                value={formData.newPassword}
                                // error={!!formError && showError}
                                // helperText={formError && showError && formError}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { transform: 'inherit' },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                id="confirmNewPassword"
                                name="confirmNewPassword"
                                label="Confirm New Password"
                                type={showConfirmPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={handleInputChange}
                                value={formData.confirmNewPassword}
                                // error={!passwordsMatch && showError}  // Only show error if passwords don't match on "Save"
                                // helperText={!passwordsMatch && showError && "Passwords do not match"}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { transform: 'inherit' },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <div className="password-strength">
                            <p>Password strength:</p>
                            <div className={passwordStrength.length ? 'valid' : 'invalid'}>
                                {passwordStrength.length ? <CheckCircleOutline /> : <ErrorOutline />} At least 8 characters
                                </div>
                            <div className={passwordStrength.mix ? 'valid' : 'invalid'}>
                                {passwordStrength.mix ? <CheckCircleOutline /> : <ErrorOutline />} Upper and lowercase letters
                                </div>
                            <div className={passwordStrength.special ? 'valid' : 'invalid'}>
                                {passwordStrength.special ? <CheckCircleOutline /> : <ErrorOutline />} Special character
                                </div>
                        </div>
                        <div className="profile_wrapperbtn">
                            <button type="button" className="btnSubmit cancel" onClick={() => setFormData({ newPassword: '', confirmNewPassword: '' })}>Cancel</button>
                            <button type="submit" className="btnSubmit save" onClick={handleSubmit}>Save</button>
                            <Snackbar
                                open={open}
                                className={classForToast == "error" ? "error" :"success"}
                                anchorOrigin={ {vertical: 'top', horizontal: 'right'} }
                                autoHideDuration={5000} 
                                message={message}
                                onClose={handleClose}
                            />
                        </div>
                        {/* {successMessage && <div className="success-message">{successMessage}</div>} */}
                    </div>
                </div>
                <div className="update_wrapper security_check_wrapper">
                    <div className="profile-form">
                        <h2>Security Settings</h2>
                        <div className="securityChecks">
                            <div className="sc_heading">
                                <p>Enable Multi Factor Authentication</p>
                                <span>A Brief Description goes here</span>
                            </div>
                            <div className="sc_toggle">
                                <FormGroup>
                                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                        <AntSwitch disabled checked={mfa} onChange={() => mfaHandler(!mfa)} inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="profile_wrapperbtn">
                            <button type="submit" className="btnSubmit save">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <ActivityLog />
        </div>
    );
};

export default Profile;
