import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { jwtDecode } from "jwt-decode";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import { LoginValue } from "../../Actions/LoginPageAction";
import arrow_sidebar from "../../assets/images/arrow_sidebar.svg"
import arrowSidebarOff from "../../assets/images/arrowSidebarOff.svg"
const SideBar = () => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState("");
  const [sideBarExCp, setSideBarExCp] = useState(false);
  const logoutWrapper = () => {
    localStorage.removeItem("token");
    dispatch(LoginValue(false));
    window.location.reload();
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    setUserRole(decodedToken.userRole);
    console.log(decodedToken);
  }, []);

  return (
    <div className="SideBar_wrapper">
      <button className={`sideBar_excp_btn ${sideBarExCp ? "on" : "off"}`}  onClick={prev => setSideBarExCp(!sideBarExCp)}><img src={sideBarExCp ? arrow_sidebar : arrowSidebarOff} /></button>
      <ul className={`sideBar_nav_listing ${sideBarExCp ? "show" : "hide"}`}>
        <li>
          <NavLink to="/dashboard" activeClassName="active">
            <span className="browse"></span>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/floors" activeClassName="active">
            <span className="floor"></span>
            <span>Floors</span>
          </NavLink>
        </li>
        {userRole === "SUPER_ADMIN" && (
          <>
            <li>
              <NavLink to="/floorManagement" exact activeClassName="active">
                <span className="floormangement"></span>
                <span>Floor Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/manageUser" activeClassName="active">
                <span className="proj"></span>
                <span>Manage User</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/device" activeClassName="active">
                <span className="proj"></span>
                <span>Devices</span>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink to="/history" activeClassName="active">
            <span className="browse_activity"></span>
            <span>History</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/analytics" activeClassName="active">
            <span className="monitoring"></span>
            <span>Analytics</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/help" activeClassName="active">
            <span className="help"></span>
            <span>Help</span>
          </NavLink>
        </li>
        <li>
          <a style={{ cursor: "pointer" }} onClick={logoutWrapper}>
            <span className="logout"></span>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
