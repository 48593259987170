import React, { useState, useEffect } from "react";
import { Switch, Stack, FormGroup, Button, styled } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../../config/apiConfig";
import checkImg from "../../../assets/images/check_circle.svg";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  borderRadius: 16,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(8, 109, 171, 1)",
        ...theme.applyStyles("dark", {
          backgroundColor: "rgba(8, 109, 171, 1)",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 16,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
const SecuritySettings = () => {
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [drillModeEnabled, setDrillModeEnabled] = useState(false);
  const [initialSettings, setInitialSettings] = useState({});
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/auth/getAllListGlobalAppSetting`
        );
        const settings = response.data.allGlobalSettings;

        const mfa = settings.find((item) => item.code === "mfa_enabled");
        const drillMode = settings.find((item) => item.code === "drill_mode");

        const initialState = {
          mfaEnabled: mfa?.value === "1",
          drillModeEnabled: drillMode?.value === "1",
        };

        setMfaEnabled(initialState.mfaEnabled);
        setDrillModeEnabled(initialState.drillModeEnabled);
        setInitialSettings(initialState);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const hasChanges =
    mfaEnabled !== initialSettings.mfaEnabled ||
    drillModeEnabled !== initialSettings.drillModeEnabled;

  const handleSubmit = async () => {
    const updateData = [
      {
        code: "mfa_enabled",
        value: mfaEnabled ? "1" : "0",
      },
      {
        code: "drill_mode",
        value: drillModeEnabled ? "1" : "0",
      },
    ];

    try {
      const response = await axios.put(
        `${baseUrl}/auth/updateGlobalSettingCode`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessDialog(true);
        setInitialSettings({ mfaEnabled, drillModeEnabled });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setShowSuccessDialog(false);
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };


  return (
    <div className="notifications_wrapper">
      <div className="heading">
        Notifications
        <div className="noti">
          <div className="sc_heading">
            <p>Multi Factor Authentication</p>
            <span>If enabled, the user will have to go through the multi factor authentication when logging in.</span>
          </div>
          <div className="sc_toggle">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={mfaEnabled}
                  onChange={() => setMfaEnabled(!mfaEnabled)}
                  inputProps={{ "aria-label": "MFA toggle" }}
                />
              </Stack>
            </FormGroup>
          </div>
        </div>
        <div className="noti">
          <div className="sc_heading">
            <p>Drill Mode</p>
            <span>If enabled, the application will work in Drill Mode. Certain functionalities will be disabled.</span>
          </div>
          <div className="sc_toggle">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={drillModeEnabled}
                  onChange={() => setDrillModeEnabled(!drillModeEnabled)}
                  inputProps={{ "aria-label": "Drill Mode toggle" }}
                />
              </Stack>
            </FormGroup>
          </div>
        </div>
        {/* Submit Button */}
        <Button variant="contained" onClick={handleSubmit} style={{ height: "40px", marginLeft: "85%" }} className="save" disabled={!hasChanges}>
          Save
        </Button>

        <Dialog
          open={showSuccessDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            handleCloseSuccessDialog();
          }}
          PaperProps={{
            style: {
              width: "479px",
              height: "300px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              textAlign: "center",
            },
          }}
        >
          <div className="dialog-title">
            <h2 className="dialog-title-text">Security Setting</h2>
            <IconButton onClick={handleCloseSuccessDialog} className="icon-container">
              <CloseIcon className="icon" />
            </IconButton>
          </div>
          <div>
            <img src={checkImg} alt="Success" style={{ width: "60px", marginBottom: "20px" }} />
            <h2 style={{ fontFamily: "Barlow Semi Condensed", fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
              Success
            </h2>
            <p style={{ fontFamily: "Barlow Semi Condensed", fontSize: "16px", fontWeight: 400, color: "#323452", marginBottom: "30px" }}>
              Security Setting has been updated
            </p>

          </div>
        </Dialog >
      </div>
    </div>
  );
};

export default SecuritySettings;
