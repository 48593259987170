const initialState = {
  floorData: [],
  activeFloorData: 1,
  activeFloorPRintData: false,
  activeFloorRef: null,
};
const FloormapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FloorData":
      return {
        ...state,
        floorData: action.payload,
      };
    case "activeFloorPlan":
      return {
        ...state,
        activeFloorData: action.payload,
      };
    case "SET_ACTIVE_FLOOR_PRINT_VIEW":
      return {
        ...state,
        activeFloorPRintData: action.payload,
      };
    case "SET_ACTIVE_FLOOR_REF":
      return {
        ...state,
        activeFloorRef: action.payload,
      };
    default:
      return state;
  }
};

export default FloormapReducer;
