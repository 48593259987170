import React, { useState, useEffect } from "react";
import { Box, FormControl, Select, MenuItem, Button } from "@mui/material";
import axios from "axios";
import { prefrenceData } from "./FieldJson";
import { baseUrl } from "../../../config/apiConfig";
import checkImg from "../../../assets/images/check_circle.svg";
import CustomizeAudio from "./CustomizeAudio";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../GlobalSetting.scss";

const MediaSettings = () => {
  const [formData, setFormData] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    const fetchGlobalSettings = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/auth/getAllListGlobalAppSetting`
        );
        if (response.status === 200) {
          const globalSettings = response.data.allGlobalSettings;
          const videoLengthSetting = globalSettings.find(
            (setting) => setting.code === "video_length"
          );

          if (videoLengthSetting) {
            setSelectedValue(videoLengthSetting.value);
            setFormData((prevData) => ({
              ...prevData,
              video_length: videoLengthSetting.value,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching global settings:", error);
      }
    };

    fetchGlobalSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      setIsSaveEnabled(Object.values(updatedData).some((val) => val !== ""));
      return updatedData;
    });
    setSelectedValue(value);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedValue) {
      console.error("No value selected");
      return;
    }

    const body = [
      {
        code: "video_length",
        value: selectedValue,
      },
    ];

    try {
      const response = await axios.put(
        `${baseUrl}/auth/updateGlobalSettingCode`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessDialog(true);
        setIsSaveEnabled(false);
      }
    } catch (error) {
      console.error("Error updating video length:", error);
    }
  };

  return (
    <div className="media_control_wrapper">
      <div className="media_wrapper">
        <div className="heading">
          Notifications
         
        </div>
        <div className="dropdown-warpper">
            <Box component="form" noValidate autoComplete="off">
              {prefrenceData.map((preference) => (
                <FormControl
                  key={preference.id}
                  style={{
                    marginTop: "7%",
                    width: "256px",
                  }}
                >
                  <div
                    variant="standard"
                    htmlFor={preference.id}
                    className="sub-heading"
                  >
                    {preference.label}
                  </div>
                  <Select
                    id={preference.id}
                    name={preference.name}
                    value={formData[preference.name] || selectedValue}
                    onChange={handleChange}
                    displayEmpty
                    style={{
                      border: "1px solid #4E4E4E",
                      borderRadius: "6px",
                      fontFamily: "Barlow Semi Condensed",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.0275em",
                      textAlign: "left",
                      bottom: "10px",
                      position: "relative",
                    }}
                    sx={{
                      "&:hover": {
                        outline: "none",
                        border: "1px solid #4E4E4E",
                      },
                      "&.Mui-focused": {
                        outline: "none",
                        border: "1px solid #4E4E4E",
                      },
                    }}
                    inputProps={{
                      style: {
                        outline: "none",
                      },
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Select an option
                    </MenuItem>
                    {preference.data.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </Box>
          </div>
        <div className="btn-wrapper">
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!isSaveEnabled}
            className="save"
          >
            Save
          </Button>
        </div>
      </div>
      <CustomizeAudio />
      <Dialog
          open={showSuccessDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            handleCloseSuccessDialog();
          }}
          PaperProps={{
            style: {
              width: "479px",
              height: "300px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              textAlign: "center",
            },
          }}
        >
          <div className="dialog-title">
            <h2 className="dialog-title-text">Media Setting</h2>
            <IconButton onClick={handleCloseSuccessDialog} className="icon-container">
              <CloseIcon className="icon" />
            </IconButton>
          </div>
          <div>
            <img src={checkImg} alt="Success" style={{ width: "60px", marginBottom: "20px" }} />
            <h2 style={{ fontFamily: "Barlow Semi Condensed", fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
              Success
            </h2>
            <p style={{ fontFamily: "Barlow Semi Condensed", fontSize: "16px", fontWeight: 400, color: "#323452", marginBottom: "30px" }}>
              Media Setting has been updated
            </p>

          </div>
        </Dialog >
    </div>
  );
};

export default MediaSettings;
