export const fieldUser = [
    {
        mainHead: "Email",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "SMS",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "Push Notification",
        desText: "A Brief Description goes here",
        disabled: true
    },
]

export const prefrenceData = [
    {
        id: "videoLength",
        name: "videoLength",
        label: "Video Length",
        type: "select",
        data: [
            { "value": "10", "label": "10" },
            { "value": "20", "label": "20" },
            { "value": "30", "label": "30" },
        ]
    },
]
