import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import {
  Box,
  NativeSelect,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import floorOneMap from "../../assets/images/floorOneMap.png";
import undoImg from "../../assets/images/undo.svg";
import redoImg from "../../assets/images/redo.svg";
import Footer from "../Footer/Footer";
import PushButton from "../../assets/images/PushButton.svg";
import SpeakerButton from "../../assets/images/SpeakerButton.svg";
import strobeButton from "../../assets/images/strobeButton.svg";
import cameraButton from "../../assets/images/cameraButton.svg";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import "./AddDevices.scss";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { debounce } from "lodash";
import { fastObjectShallowCompare } from "@mui/x-internals/fastObjectShallowCompare";
const AddDevices = (props) => {
  const [items, setItems] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState();
  const [expand, setExpand] = useState(true);
  const [rows, setRows] = useState([]);
  const [floorDetails, setFloorDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [sideberItemDisabled, setSideberItemDisabled] = useState(false)
  const [isDragging, setIsDragging] = useState(false);

  
  const blueprintRef = useRef(null);
  const blueprintmainWrapperRef = useRef(null);
  const { id } = useParams();
  const columns = [
    {
      field: "Device",
      headerName: "Device",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={
                params.row.Type === "Camera"
                  ? cameraButton
                  : params.row.Type === "Speaker"
                  ? SpeakerButton
                  : params.row.Type === "Strobe Light"
                  ? strobeButton
                  : params.row.Type === "Push-Button"
                  ? PushButton
                  : ""
              }
              alt="Device"
              className="blurPrintLink"
            />
          </div>
        );
      },
    },
    {
      field: "DeviceName",
      headerName: "Device Name",
      flex: 2,
      hideable: false,
    },
    {
      field: "Type",
      headerName: "Type",
      flex: 1,
      hideable: false,
    },
    // {
    //   field: 'Status',
    //   headerName: 'Status',
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div>
    //       {/* {params.value ? params.value : 'N/A'} */}
    //       {params.value == 1 ? "StandBy" : params.value == 2 ? "Failure" : params.value == 3 ? "Activated" : 'N/A'}
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleImageLoad = () => {
    setIsLoading(false);
    setTimeout(() => setIsTransitioning(false), 10000);
  };
  const saveState = useCallback(
    (newItems) => {
      setUndoStack((prevStack) => [...prevStack, items]);
      setItems(newItems);
      setRedoStack([]);
    },
    [items]
  );

  const onDragStart = useCallback(
    (e, params) => {
      setIsDragging(true); // Start dragging

      const dragData = {
        id: params.row.id,
        name: params.row.name,
        cssXCoordinate: params.row.cssXCoordinate,
        cssYCoordinate: params.row.cssYCoordinate,
        type: params.row.type,
        deviceLocationName: selectedFloor.floorName,
        deviceLocationId: selectedFloor.id,
        triggeredByDeviceId: [0],
        deleted: false,
        createdAt: params.row.createdAt,
        modifiedAt:  new Date().toISOString(),
        createdBy: params.row.createdBy,
        modifiedBy: 0,
        triggeredBy: 0,
        deviceMake: params.row.deviceMake,
        status: params.row.status,
        serialNumber: params.row.serialNumber,
        installed: params.row.installed,
        macAddress: params.row.macAddress,
        ipAddress: params.row.macAddress,
        topicName: params.row.topicName,
        cameraType: params.row.cameraType,
        streamUrl: params.row.streamUrl,
        videoLength: params.row.videoLength,
      };
      // Serialize only dragData (no circular references)
      e.dataTransfer.setData("droppedItem", JSON.stringify(dragData));
    },
    [selectedFloor]
  );
  const onDraggedStart = useCallback(
    (e, params) => {
      setIsDragging(true); // Start dragging

      const dragData = {
        id: params.id,
        name: params.name,
        cssXCoordinate: params.cssXCoordinate,
        cssYCoordinate: params.cssYCoordinate,
        type: params.type,
        deviceLocationName: selectedFloor.floorName,
        deviceLocationId: selectedFloor.id,
        triggeredByDeviceId: [0],
        deleted: false,
        createdAt: params.createdAt,
        modifiedAt:  new Date().toISOString(),
        createdBy: params.createdBy,
        modifiedBy: 0,
        triggeredBy: 0,
        deviceMake: params.deviceMake,
        status: params.status,
        serialNumber: params.serialNumber,
        installed: params.installed,
        macAddress: params.macAddress,
        ipAddress: params.macAddress,
        topicName: params.topicName,
        cameraType: params.cameraType,
        streamUrl: params.streamUrl,
        videoLength: params.videoLength,
      };
      // Serialize only dragData (no circular references)
      e.dataTransfer.setData("droppedItem", JSON.stringify(dragData));
    },
    [selectedFloor]
  );

  const updateDeviceCoordinates = async (device) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const decodedToken = jwtDecode(token);
      const url = `https://lockdownexperts.biz/api/v1/device/update/${device.id}`;
      const payload = {
        id: device.id,
        name: device.name,
        cssXCoordinate: device.cssXCoordinate,
        cssYCoordinate: device.cssYCoordinate,
        type: device.type,
        deviceLocationName: selectedFloor.floorName,
        deviceLocationId: selectedFloor.id,
        triggeredByDeviceId: [0],
        deleted: false,
        createdAt: device.createdAt,
        modifiedAt: new Date().toISOString(),
        createdBy: device.createdBy,
        modifiedBy: decodedToken.userGuid,
        triggeredBy: 0,
        deviceMake: device.deviceMake,
        status: device.status,
        serialNumber: device.serialNumber,
        installed: device.installed,
        macAddress: device.macAddress,
        ipAddress: device.ipAddress,
        topicName: device.topicName,
        cameraType: device.cameraType,
        streamUrl: device.streamUrl,
        videoLength:device.videoLength,
      };
      // Make the PUT request with the complete device object
      return await axios.put(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error updating device coordinates:", error);
    }
  };

  
  const onDrop = useCallback(
    async (e) => {
      e.preventDefault();
  
      // Get dropped item data
      const droppedItemDataString = e.dataTransfer.getData("droppedItem");
      const droppedItem = JSON.parse(droppedItemDataString);
  
      // Get blueprint dimensions
      const blueprintRect = blueprintRef.current.getBoundingClientRect();
  
      // Calculate offsets relative to the blueprint
      let xOffset = e.clientX - blueprintRect.left;
      let yOffset = e.clientY - blueprintRect.top;
  
      // Define a boundary margin in pixels (20px)
      const boundaryMargin = 30;
  
      // Clamp offsets to ensure the item stays within the boundaries
      xOffset = Math.min(
        Math.max(xOffset, boundaryMargin), // Ensure it doesn't go below boundaryMargin
        blueprintRect.width - boundaryMargin // Ensure it doesn't exceed the right boundary
      );
  
      yOffset = Math.min(
        Math.max(yOffset, boundaryMargin), // Ensure it doesn't go below boundaryMargin
        blueprintRect.height - boundaryMargin // Ensure it doesn't exceed the bottom boundary
      );
  
      // Convert the clamped offsets to percentage-based coordinates
      const newX = Math.round((xOffset / blueprintRect.width) * 100);
      const newY = Math.round((yOffset / blueprintRect.height) * 100);
  
      // Add the dropped item if not already in the items array
      if (!items.some((element) => element.id === droppedItem.id)) {
        items.push(droppedItem);
      }
  
      // Update the item's coordinates in the items array
      const updatedItems = items.map((item) => {
        if (item.id === droppedItem.id) {
          return { ...item, cssXCoordinate: newX, cssYCoordinate: newY };
        }
        return item;
      });
  
      // Update state and undo stack
      setItems(updatedItems);
      saveState(updatedItems);
  
      // Update the database asynchronously after the state update
      const updatedDevice = updatedItems.find(
        (item) => item.id === droppedItem.id
      );
  
      if (updatedDevice) {
        await updateDeviceCoordinates(updatedDevice);
        // Optional: Refetch data for consistency
        await fetchFloorDevices();
        await fetchData();
      }
    },
    [items, saveState, updateDeviceCoordinates]
  );
  

  const onDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const processedRows = useMemo(() =>
      Array.isArray(rows)
        ? rows.map((row) => ({
            ...row,
            id: row.id,
            DeviceName: row.name || "N/A",
            Type:typeof row.type === "object" && row.type
                ? row.type.name
                : row.type || "N/A",
                Status: row.status || "N/A",
            deviceMake:row.deviceMake || "N/A",
            serialNumber:row.serialNumber || "N/A",
            macAddress: row.macAddress || "N/A",
            ipAddress: row.ipAddress || "N/A",
            topicName:row.topicName || "N/A",
            cameraType: row.cameraType || 'N/A',
            streamUrl: row.streamUrl || 'N/A',
            videoLength: row.videoLength || 0,
          }))
        : [],
    [rows]
  );

  const debouncedUpdateDeviceCoordinates = useCallback(
    debounce(async (device) => {
      try {
        await updateDeviceCoordinates(device);
      } catch (error) {
        console.error("Error updating device coordinates:", error);
      }
    }, 300),
    []
  );
  const onDragEnd = useCallback(() => {
    setIsDragging(false); // Reset dragging state
  }, []);
  const undo = useCallback(async () => {
    if (undoStack.length > 0) {
      const previousState = undoStack[undoStack.length - 1];
      setRedoStack((prevStack) => [...prevStack, items]);
      setUndoStack((prevStack) => prevStack.slice(0, -1));
      setItems(previousState);

      if (Array.isArray(previousState)) {
        for (const device of previousState) {
          if (device.cssXCoordinate && device.cssYCoordinate) {
            try {
              await updateDeviceCoordinates(device);
            } catch (error) {
              console.error("Undo failed to update device:", error);
            }
          }
        }
      }

      // Refresh floor devices
      await fetchFloorDevices();
    }
  }, [undoStack, items, updateDeviceCoordinates]);

  const redo = useCallback(async () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setUndoStack((prevStack) => [...prevStack, items]);
      setRedoStack((prevStack) => prevStack.slice(0, -1));
      setItems(nextState);

      if (Array.isArray(nextState)) {
        for (const device of nextState) {
          if (device.cssXCoordinate && device.cssYCoordinate) {
            try {
              await updateDeviceCoordinates(device);
            } catch (error) {
              console.error("Redo failed to update device:", error);
            }
          }
        }
      }

      // Refresh floor devices
      await fetchFloorDevices();
    }
  }, [redoStack, items, updateDeviceCoordinates]);

  // const handleFloorChange = (e) => {
  //   const selectedFloorId = e.target.value;
  //   const selectedFloor = floorDetails.find(floor => floor.id === parseInt(selectedFloorId));
  //   if (selectedFloor) {
  //     setIsTransitioning(true);
  //     setSelectedFloor(selectedFloor);
  //     setIsLoading(true);
  //   }
  // };
  const renderDraggableItems = useMemo(
    () => {
      if (!expand) {
        return null; // Do not render if expand is false
      }
  
      return (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={processedRows}
            columns={columns.map((column) => ({
              ...column,
              renderCell: (params) => (
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, params)}
                  className="sidbarFields"
                  style={{
                    pointerEvents: `${sideberItemDisabled ? "none" : ""}`,
                    cursor: `${sideberItemDisabled ? "none" : "move"}`,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {column.renderCell ? column.renderCell(params) : params.value}
                </div>
              ),
            }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 6,
                },
              },
            }}
            pageSizeOptions={[6]}
            disableRowSelectionOnClick
            pagination
            hideFooter={processedRows.length === 0} // Hide footer and pagination if no rows
          />
        </Box>
      );
    },
    [expand, processedRows, columns, onDragStart, sideberItemDisabled] // Add `expand` as a dependency
  );
  

  const renderDraggableOnMap = useMemo(() => {
    if (isLoading || !Array.isArray(items)) return null; // Early return for loading or invalid items

    return items.map((item) => {
      if (item.cssYCoordinate === 0 || item.cssXCoordinate === 0) return null; // Skip invalid coordinates
      // Handle item rendering logic
      const itemImageSrc = (() => {
        switch (item.type?.name) {
          case "Camera":
            return cameraButton;
          case "Speaker":
            return SpeakerButton;
          case "Strobe Light":
            return strobeButton;
          case "Push-Button":
            return PushButton;
          default:
            return "";
        }
      })();

      return (
        <div
          key={item.id}
          draggable
          onDragStart={(e) => onDraggedStart(e, item)}
          className="dropedItems"
          style={{
            position: "absolute",
            top: `${item.cssYCoordinate}%`,
            left: `${item.cssXCoordinate}%`,
            cursor: "move",
          }}
        >
          <img
            src={itemImageSrc}
            alt={`draggable item-${item.id}`}
            width="30"
            height="30"
          />
          <span
            className="deleteIcon"
            onClick={(e) => deleteAddedItem(e, item)}
          ></span>
        </div>
      );
    });
  }, [items, onDraggedStart, isLoading]);

  const fetchData = async () => {
    setSideberItemDisabled(true)
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/device/data/getDevicesMissingLocation`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setRows(response.data);
        setSideberItemDisabled(false)
        // setItems(
        //   response.data.map((device) => ({
        //     ...device,
        //     x: device.cssXCoordinate,
        //     y: device.cssYCoordinate,
        //     name: device.name,
        //   }))
        // );
        handleImageLoad();
      }
    } catch (error) {
      console.error("Error making request:", error);
      setSideberItemDisabled(false)
    }
  };
  const fetchFloorDevices = async () => {
    try {
      if (!selectedFloor || !selectedFloor.id) {
        console.error("Selected floor is undefined or missing id");
        return; // Exit early if selectedFloor is invalid
      }
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/device/set-coord/data/location/${selectedFloor.id}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setItems(
          response.data.map((device) => ({
            ...device,
            cssXCoordinate: device.cssXCoordinate,
            cssYCoordinate: device.cssYCoordinate,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching floor devices:", error);
    }
  };
  const updatedFloor = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/floor/floorList`; // Fetch all floors
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const floors = response.data;
        setFloorDetails(floors);

        // Find and set the specific floor by ID
        const selectedFloor = floors.find(
          (floor) => floor.id === parseInt(id, 10)
        );
        if (selectedFloor) {
          setSelectedFloor(selectedFloor); // Set the selected floor in state
        } else {
          console.warn(`Floor with ID ${id} not found`);
        }
      }
    } catch (error) {
      console.error("Error fetching floor details:", error);
    }
  };
  const deleteAddedItem = async (e, device) => {
    e.preventDefault();
  
    // Optimistically remove the device from the `items` state //  Add this because on delte item no rows showing
    setItems((prevItems) => prevItems.filter((item) => item.id !== device.id));

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const decodedToken = jwtDecode(token);
      const formDataToSend = {
        id: device.id,
        name: device.name,
        cssXCoordinate: 0,
        cssYCoordinate: 0,
        type: device.type,
        deviceLocationName: selectedFloor.floorName,
        deviceLocationId: null,
        triggeredByDeviceId: [0],
        deleted: false,
        createdAt: device.createdAt,
        modifiedAt: new Date().toISOString(),
        createdBy: device.createdBy,
        modifiedBy: decodedToken.userGuid,
        triggeredBy: 0,
        deviceMake: device.deviceMake,
        status: device.status,
        serialNumber: device.serialNumber,
        installed: device.installed,
        macAddress: device.macAddress,
        ipAddress: device.ipAddress,
        topicName: device.topicName,
        cameraType: device.cameraType,
        streamUrl: device.streamUrl,
        videoLength:device.videoLength,
      };
      const url = `https://lockdownexperts.biz/api/v1/device/update/${device.id}`;
      const response = await axios({
        method: "PUT",
        url: url,
        data: formDataToSend,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        // Refetch data to ensure consistency//   Add await and comment setrows because on delte item no rows showing
        await fetchData();
        await fetchFloorDevices();
        // setRows(response.data);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
  
      // Revert optimistic update in case of an error// Add this because on delte item no rows showing
      setItems((prevItems) => [...prevItems, device]);
    }
  };
  
  useEffect(() => {
    if (id) {
      updatedFloor(id); // Pass ids or the specific floor ID
    }
  }, [id]);
 

  useEffect(() => {
    if (selectedFloor && selectedFloor.id) {
      fetchFloorDevices();
    }
  }, [selectedFloor]);

  return (
    <div className="deviceMangement_wrapper">
      <div className="main_heading main_heading_addDevices">
        <h1>Assign Devices</h1>
        <h3>{selectedFloor?.floorName}</h3>
      </div>
      <div className="AddFloor addDeviceAddFloor addDevice_flor_drop">
        <Box sx={{ minWidth: 340 }} className="floor-dropdown">
          {/* <FormControl fullWidth className="innerFields ">
            <InputLabel variant="standard" 
            // htmlFor={data.id}
              shrink={false}
            >
              Floor
            </InputLabel>
            <NativeSelect
              // id={data.id}
              // name={data.name}
              // value={formData.type ?.id || ''}
              onChange={(e) => handleFloorChange(e)}
            >
              <option value="" disabled>
                Select an option
              </option>
              {floorDetails &&
                floorDetails.map((floor) => (
                  <option key={floor.id} value={floor.id}>
                    {floor.floorName}
                  </option>
                ))}

            </NativeSelect>
          </FormControl> */}
          <div className="undoRdoBtn">
            <button onClick={undo} disabled={undoStack.length === 0}>
              <img src={undoImg} alt="img" />
            </button>
            <button onClick={redo} disabled={redoStack.length === 0}>
              <img src={redoImg} alt="img" />
            </button>
          </div>
        </Box>
        {/* <button className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Add Device</button> */}
      </div>
      <div className="inner_Wrapper addDeviceInnerWrppar"   >
        <div className="mapwrapper">
          <div
            className={`inner_addDevicewrapper ${
              expand ? "active" : "inactive"
            }`}
            onDrop={onDrop}
            onDragOver={onDragOver}
            ref={blueprintmainWrapperRef}
          >
            {!isLoading ? (
              <img
                src={selectedFloor?.floorImageUrl || "No BluePrint"}
                className={`main_bg_addv ${isTransitioning ? "fade-in" : ""}`}
                alt="Blueprint"
                ref={blueprintRef}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && renderDraggableOnMap}
            <div 
              // style={{
              //       pointerEvents: isDragging ? 'none' : 'auto',
              //       marginTop: isDragging ? '10px' : '0px', // You can adjust styles as needed
              //     }} 
              >
              <Footer floorMapRef={blueprintmainWrapperRef} />
            </div>
          </div>
          <div className={`inner_sideBar ${expand ? "active" : "inactive"}`}>
            <button
              className="excpBtn"
              onClick={() => setExpand(!expand)}
            ></button>
            {renderDraggableItems}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDevices;
