import React, { useState, useEffect } from "react";
import { Radio, Button } from "@mui/material";
import { baseUrl } from "../../../config/apiConfig";
import checkImg from "../../../assets/images/check_circle.svg";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EmergencySettings = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/auth/getAllListGlobalAppSetting`
        );
        if (response.ok) {
          const data = await response.json();
          const allGlobalSettings = data.allGlobalSettings;

          const nextGen911Setting = allGlobalSettings.find(
            (setting) => setting.code === "next_gen_911"
          );
          const setting911 = allGlobalSettings.find(
            (setting) => setting.code === "911"
          );

          if (nextGen911Setting?.value === "1") {
            setSelectedOption("next_gen_911_functionality");
          } else if (setting911?.value === "1") {
            setSelectedOption("911_functionality");
          }
        } else {
          console.error("Failed to fetch global settings:", response.statusText);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsSaveEnabled(true);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleSave = async () => {
    const payload = [
      {
        code: "911",
        value: selectedOption === "911_functionality" ? "1" : "0",
      },
      {
        code: "next_gen_911",
        value: selectedOption === "next_gen_911_functionality" ? "1" : "0",
      },
    ];

    try {
      const response = await fetch(
        `${baseUrl}/auth/updateGlobalSettingCode`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        console.log("API call successful. Settings updated successfully.");
        setShowSuccessDialog(true);
      } else {
        console.error("API call failed:", response.statusText);
        alert("Failed to save settings. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while saving settings.");
    }
  };

  const CustomRadio = (props) => (
    <Radio
      {...props}
      sx={{
        "&.Mui-checked": {
          color: "#0078d4",
          outline: "none",
        },
        "& .MuiSvgIcon-root": {
          fontSize: 24,
        },
      }}
    />
  );

  return (
    <div className="em-wrapper">
      <div className="heading">
        Notifications
        <div className="settings">
          <p>911 functionality.</p>
          <CustomRadio
            value="911_functionality"
            checked={selectedOption === "911_functionality"}
            onChange={handleOptionChange}
          />
        </div>
        <div className="settings1">
          <p>Next Gen 911 functionality.</p>
          <div className="em_radio">
            <CustomRadio
              value="next_gen_911_functionality"
              checked={selectedOption === "next_gen_911_functionality"}
              onChange={handleOptionChange}
            />
          </div>
        </div>
        {/* Save Button */}
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isSaveEnabled}
            style={{
              backgroundColor: isSaveEnabled ? "#003e9c" : "#ccc",
              color: isSaveEnabled ? "#fff" : "#666",
              textTransform: "none",
              padding: "8px 24px",
              fontSize: "16px",
              fontFamily: "Arial, sans-serif",
              border: "none",
              cursor: isSaveEnabled ? "pointer" : "not-allowed",
            }}
          >
            Save
          </Button>
        </div>
      </div>

      <Dialog
        open={showSuccessDialog}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          handleCloseSuccessDialog();
        }}
        PaperProps={{
          style: {
            width: "479px",
            height: "300px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            textAlign: "center",
          },
        }}
      >
        <div className="dialog-title">
          <h2 className="dialog-title-text">Emergency Setting</h2>
          <IconButton onClick={handleCloseSuccessDialog} className="icon-container">
            <CloseIcon className="icon" />
          </IconButton>
        </div>
        <div>
          <img src={checkImg} alt="Success" style={{ width: "60px", marginBottom: "20px" }} />
          <h2 style={{ fontFamily: "Barlow Semi Condensed", fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
            Success
          </h2>
          <p style={{ fontFamily: "Barlow Semi Condensed", fontSize: "16px", fontWeight: 400, color: "#323452", marginBottom: "30px" }}>
            Emergency Setting has been updated
          </p>

        </div>
      </Dialog >
    </div>
  );
};

export default EmergencySettings;
