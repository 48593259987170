import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../config/apiConfig";
import { LoginValue } from "../../Actions/LoginPageAction";
import "./MFADialog.css";

const AuthenticatorAppDialog = ({ onClose, barcode }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(!code ? "Please enter the verification code." : "Please enter a 6-digit code.");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/auth/verify`, {
        email: location?.state?.username,
        code,
      });

      if (response.data.verifyCode) {
          // Mark MFA as completed
          localStorage.setItem("isMFACompleted", "true");
          // Mark user as authenticated
          localStorage.setItem("isAuthenticated", "true");
          // Save the default path
          localStorage.setItem("lastPath", "/floors");
          // Dispatch Redux action and navigate
          dispatch(LoginValue(true));
          // navigate("/dashboard", { replace: true });
      } else {
        setErrorMessage("Wrong verification code.");
      }
    } catch (error) {
      console.error("Failed to verify code:", error);
      setErrorMessage("An error occurred during verification. Please try again.");
    }
  };

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        onClose();
      }}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          width: "600px",
          height: "578px",
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "white", // Adjust the background color
          },
        },
      }}
    >
      <div className="dialog-title">
        <h2 className="dialog-title-text">Multi-Factor Authentication</h2>
        <IconButton onClick={onClose} className="icon-container">
          <CloseIcon className="icon" />
        </IconButton>
      </div>
      <div className="dialog-content">
        <p>
          Scan this QR code below with your authentication app. It will generate
          a 6-digit code to verify your account.
        </p>
        <img
          src={barcode}
          alt="QR Code"
          style={{
            display: "block",
            margin: "0 auto",
            width: "211px",
            height: "216px",
            border: "1px solid #D9D9D9",
          }}
        />
        <div style={{ display: "inline-grid", marginTop: "40px" }}>
          <label htmlFor="verification-code">
            Enter the 6-Digit Verification Code
          </label>
          <input
            type="text"
            id="verification-code"
            placeholder="XXXXXX"
            maxLength="6"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={{
              padding: "10px",
              marginTop: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "274px",
            }}
          />
        </div>
        {errorMessage && (
          <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
        )}
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "35px",
            bottom: "35px",
          }}
        >
          <Button onClick={handleVerification} variant="contained" color="primary" className="next-button">
            Next
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AuthenticatorAppDialog;
