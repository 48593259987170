import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Snackbar 
} from '@mui/material';

import { fieldUser } from "./FieldJson"
import { Validation } from '../../../Validation/Validation';
import {baseUrl} from '../../../config/apiConfig'
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import '../profile.scss'

const UserDtails = (props) => {
    const [formData, setFormData] = useState({
        userName:'',
        accountType: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber:''
    });
    const [classForToast, setClassForToast] = useState("");
    const [formError, setFormError] = useState("");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const {editData } = props
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const requiredFields = ["firstName", "lastName", "email", "phoneNumber"];

    // Validation function
    const handleValidation = () => {
        let isValid = true;
        let errors = {};
        // Loop over required fields
        requiredFields.forEach((field) => {
            if (Validation.empty(formData[field])) {
                // General validation for other fields
                isValid = false;
                errors[field] = `Please enter ${field}`; // Generates error message like "Please enter device make"
            }
        }); // <-- Closing bracket for forEach

        // Set errors if any
        if (!isValid) {
            setFormError(errors);
        } else {
            setFormError({}); // Clear errors if valid
        }

        return isValid;
    };
   
    const handleEdit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        try {
            const formDataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                telephone:formData.phoneNumber
            };
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const response = await axios.put(`${baseUrl}/user/${decodedToken.userGuid}`, formDataToSend, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                // setSuccessMessage(true)
                setMessage('Profile has been updated.')
                setOpen(true)
                setClassForToast("success")
            }
        } catch (error) {
            setMessage( "An error occurred");
            setOpen(true)
            setClassForToast("error")
            console.error("Error updating device:", error);
        }
    };
    const handleClose = () => {
        setFormError("")
        setFormData({
            userName:'',
            accountType: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber:''
        });
        setOpen(false)
        setMessage("")
    };
    const handleCloseToaset = () => {
        setOpen(false)
        setMessage("")
        setClassForToast("")
    };

    useEffect(() => {
        if (editData && Object.keys(editData).length > 0) {  // Only set form data if editData is not empty
            setFormData({
                userName: editData.email || "",
                accountType: editData.roleId || "",
                firstName: editData.firstName || "",
                lastName: editData.lastName|| "",
                phoneNumber: editData.telephone|| "",
                email: editData.email || "",
            });
        }
    }, [editData]);

    return (
        <>
            <div className="Modal_middle  addDeviceMiddle login-form">
            <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                        {fieldUser?.map((data, index) => {
                            return (
                                <>
                                    <TextField
                                        key={index}
                                        id={data.id}
                                        name={data.name}
                                        label={data.label}
                                        variant="outlined"
                                        disabled={data.disabled}
                                        onChange={handleInputChange}
                                        value={formData[data.name] == "string" ? "" : formData[data.name] || ''}
                                        error={!!formError[data.name]}
                                        className="innerFields"
                                        helperText={formError[data.name] || data.helperText}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                transform: 'inherit',
                                            },
                                        }}
                                    />
                                </>
                            )
                        })}
                     <div className="Modal_footer adddeviceFooter" style={{width:'100%'}}>
                        {/* Buttons */}
                        <Box sx={{ display: 'flex' }}>
                            <Button variant="contained" className="saveBtn" onClick={(e) => handleEdit(e)}>
                                Save
                            </Button>
                            <Snackbar
                                open={open}
                                anchorOrigin={ {vertical: 'top', horizontal: 'right'} }
                                className={classForToast === "error" ? "error" :"success"}
                                autoHideDuration={5000} 
                                message={message}
                                onClose={handleCloseToaset}
                            />
                        </Box>
                    </div>
                    </Box>
            </div>
        </>
    );
};

export default UserDtails;
