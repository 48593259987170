import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import SecuritySettings from './SecuritySettings/SecuritySettings'
import EmergencySettings from './EmergencySettings/EmergencySettings'
import MediaSettings from './MediaSettings/MediaSettings'
import './GlobalSetting.scss'

const Profile = () => {
    const [activeScreen, setActiveScreen] = useState('securitySetting')
    return (
        <>

            <div className="profileWrapper">
                <div className="main_heading">
                    <h1>Global Settings</h1>
                </div>
                <div className="menu_bar">
                    <ul>
                        <li>
                            <button
                                className={activeScreen === 'securitySetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('securitySetting')}
                            >
                                Security Settings
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'mediaSetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('mediaSetting')}
                            >
                                Media Settings
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'emergencySetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('emergencySetting')}
                            >
                                Emergency Settings
                </button>
                        </li>
                    </ul>
                </div>

                <div className="userPassdDetail_wrapper">
                    {activeScreen == "securitySetting" ? <SecuritySettings /> : activeScreen == "emergencySetting" ? <EmergencySettings />  : <MediaSettings />}
                </div>
            </div>

        </>
    );
};

export default Profile;
