import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../assets/images/editIcon.svg'; // Example using MUI icons
import DeleteIcon from '../../assets/images/DeleteIcon.svg'; // Example using MUI icons
import AddEditDevice from './AddEditDevice.js'
import DeleteModal from './DeleteModal'
import {baseUrl} from '../../config/apiConfig'
import axios from "axios";
import './mustyle.scss'

const DevicesComp = () => {
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState('Delete Successfully.');
    // const [selectedFloor, setSelectedFloor] = useState(1);
    const [rows, setRows] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [actionButton, setActionButton] = useState("")
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const columns = [
        {
            field: 'userid',
            headerName: 'User Id',
            flex: 1,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'emailaddress',
            headerName: 'Email Address',
            flex: 2,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            sortable: true,
            disableColumnMenu: false
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right', 
            headerAlign: 'right', 
            renderCell: (params) => (
                <div>
                    <img
                        src={EditIcon}
                        alt="edit"
                        style={{ width: '28px', height: '28px', cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleEdit(params.row)}
                    />
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                        onClick={() => {setOpenDeleteModal(true); setDeviceIdToDelete(params.row.userid)}}
                    />
                </div>
            ),
        }
    ]
    const handleEdit = (deviceData) => {
        setEditData(deviceData);
        setActionButton("edit");
        setOpen(true);
    };

    // const selectedFloorHandler = (e) =>{
    //     setSelectedFloor(e.target.value)
    // }
    const toCamelCase = (str) => {
        if (!str) return 'N/A';
        return str
            .toLowerCase()
            .replace(/[_-\s](.)/g, (_, char) => char.toUpperCase());
    };
    const processedRows = Array.isArray(rows) ? rows.map((row,index) => ({
        id: index + 1,
        userid: row.userGuid || 'N/A', // Assuming each row needs an `id` field
        name: `${row.firstName} ${row.lastName}` || 'N/A',
        emailaddress: row.email || 'N/A',
        role: toCamelCase(row.roleName) || 'N/A',
        status: row.userStatus || 'N/A',
    })) : [];
    // Listing APi 
    const fetchData = async () => {
        try {        
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${baseUrl}/getUserList`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setRows(response.data)
            } 
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        // Call the async function
        fetchData();
    }, [actionButton]);

    const handleDelete = async (id) => {
        let token = JSON.parse(localStorage.getItem("token"));
        const url = `${baseUrl}/users/${id}`; // Construct the URL with the device ID
        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                fetchData();
                setOpenToast(true)
            } else {
                console.error("Failed to delete device:", response.data);
                // Handle any errors here, such as displaying a message to the user
            }
        } catch (error) {
            console.error("Error deleting device:", error);
            // Handle any errors here, such as displaying a message to the user
        }
    };
    const handleToastClose = () => {
        setOpenToast(false)
        setMessage("")
  };
    return (
        <>
            <div className="userMangement_wrapper">
                <div
                    className="main_heading"
                >
                    <h1>Manage Users</h1>
                </div>
                <div className="AddUser">
                    <button className="addDevice" onClick={() => {setOpen(true);setActionButton("add")}}>Create New User</button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper manageUserTable_wrapper">
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={processedRows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            <AddEditDevice
                open={open}
                setOpen={setOpen}
                setActionButton={setActionButton}
                actionButton={actionButton}
                editData={editData}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={openToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={message}
                onClose={handleToastClose}
                className={"success"}
            />
        </>

    )
}

export default DevicesComp
