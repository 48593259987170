import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import mfa_call from "../../assets/images/mfa_call.png";
import mfa_mail from "../../assets/images/mfa_mail.png";
import mfa_shield from "../../assets/images/mfa_shield.png";
import "./MFADialog.css";
import AuthenticatorAppDialog from "./AuthenticatorAppDialog";
import { baseUrl } from "../../config/apiConfig";
import MFALogin from "./MFALogin";
import EmailDialog from "./EmailDialog";

const MFADialog = ({ open, onClose }) => {
  const [isAuthDialogOpen, setAuthDialogOpen] = useState(false); //QR-code dialog box
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false); //Verification dialog
  const [mfaResponseData, setMfaResponseData] = useState();
  const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
  const location = useLocation();

  const handleEmailClick = async () => {
    try {
      const response = await fetch(`${baseUrl}/auth/sendEmailVerifyCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: location.state.username
        }),
      });

      if(response.status === 200){
        setEmailDialogOpen(true)
      }
    }catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };

  const handleAuthenticatorAppClick = async () => {
    try {
      const response = await fetch(`${baseUrl}/auth/authenticate_live_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: location.state.username,
          password: location.state.password,
        }),
      });
      const data = await response.json();
      if (response.status === 200) {
        setMfaResponseData(data.secretImageUri);
      }
      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      if (data.mfaSetupStatus === true) {
        setLoginDialogOpen(true); // Open login dialog
      } else if (data.mfaSetupStatus === false) {
        setAuthDialogOpen(true); // Open Auth app dialog
      } else {
        console.error("Unexpected mfaSetupStatus value:", data.mfaSetupStatus);
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };
  return (
    <>
      {open && <div className="dialog-overlay"></div>}
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            // Prevent closing on backdrop clicks
            return;
          }
          onClose();
        }}
        fullWidth
        maxWidth="sm"
        className="dialog"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "white", // Adjust the background color
            },
          },
        }}
      >
        {/* Dialog Title */}
        <div className="dialog-title">
          <h2 className="dialog-title-text">Multi-Factor Authentication</h2>
          <IconButton onClick={onClose} className="icon-container">
            <CloseIcon className="icon" />
          </IconButton>
        </div>

        {/* Dialog Content */}
        <div className="dialog-content">
          <div className="dialog-space">
            <div className="dialog-description">
              <p>
                Prevent hackers from accessing your account with an additional
                layer of security. When you sign in, 2-Step verification helps
                ensure your personal information stays private, safe, and
                secure.
              </p>
              <p>Choose the preferred verification method.</p>
            </div>
          </div>
          <Button
            fullWidth
            className="mfa-button"
            disabled
            style={{ border: "1px dashed #5b5d75", marginBottom: "16px" }}
          >
            <div className="button-content">
              <img src={mfa_call} alt="Call Icon" className="button-image" />
              <div className="button-text">Verification by Phone/SMS</div>
            </div>
          </Button>

          <Button
            fullWidth
            className="mfa-button"
            onClick={() => handleEmailClick()}
            style={{ border: "1px dashed #5b5d75", marginBottom: "16px" }}
          >
            <div className="button-content">
              <img src={mfa_mail} alt="Mail Icon" />
              <div className="button-text">Verification by Email</div>
            </div>
          </Button>

          <Button
            fullWidth
            className="mfa-button"
            onClick={() => handleAuthenticatorAppClick()}
            style={{ border: "1px dashed #5b5d75" }}
          >
            <div className="button-content">
              <img
                src={mfa_shield}
                alt="Authenticator Icon"
                className="button-image"
              />
              <div className="button-text">
                Verification by Authenticator App
              </div>
            </div>
          </Button>
        </div>
      </Dialog>
      {/* QR Code Dialog */}
      {isAuthDialogOpen && (
        <AuthenticatorAppDialog
          onClose={() => setAuthDialogOpen(false)}
          barcode={mfaResponseData}
        />
      )}

      {/* Setup Dialog */}
      {isLoginDialogOpen && (
        <MFALogin
          open={isLoginDialogOpen}
          onClose={() => setLoginDialogOpen(false)}
        />
      )}

      {/* Email Dialog */}
      {isEmailDialogOpen && (
        <EmailDialog
          open={isEmailDialogOpen}
          onClose={() => setEmailDialogOpen(false)}
        />
      )}
    </>
  );
};

export default MFADialog;
