import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    Modal,
    NativeSelect,
    FormHelperText
} from '@mui/material';
import {
    LocalizationProvider,
    DatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import closeIcon from '../../assets/images/closeicon.svg'
import { field } from "./FieldJson"
import { Validation } from '../../Validation/Validation';
import { Container, Row, Col } from 'react-bootstrap';
import errorSign from '../../assets/images/errorSign.svg'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import checkImg from "../../assets/images/check_circle.svg";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import './style.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};
const AddEditDevice = (props) => {
    const [formData, setFormData] = useState({
        type: {},
        name: '',
        deviceMake: '',
        cameraType: '',
        streamUrl: '',
        videoLength: '',
        status: 0,
        serialNumber: '',
        dateInstalled: null,
        macAddress: '',
        ipAddress: '',
        topicName: ''
    });

    const [formError, setFormError] = useState("");
    const [renderDeviceType, setRenderDeviceType] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [testConnectionStatus, setTestConnectionStatus] = useState("");
    const [streamUrlNew, setStreamUrlNew] = useState("");
    const [deviceType, setDeviceType] = useState([]);
    const [statusType, setStatusType] = useState([]);
    const { setOpen, open, actionButton, setActionButton, editData } = props
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "streamUrl") {
            setStreamUrlNew(value);
        }
    };

    const handleDeviceTypeChange = (e) => {
        const selectedType = deviceType.find((type) => type.id === Number(e.target.value));
        setRenderDeviceType(selectedType)
        setFormData((prevState) => ({
            ...prevState,
            type: selectedType || {}
        }));
    };
    const handleStatusTypeChange = (e) => {
        const selectedStatus = statusType.find((status) => status.id === Number(e.target.value));
        setFormData((prevState) => ({
            ...prevState,
            status: selectedStatus ? selectedStatus.id : 0
        }));
    };
    // Date change handler
    const handleDateChange = (newValue) => {
        setFormData(prevState => ({
            ...prevState,
            dateInstalled: newValue ? dayjs(newValue) : null
        }));
    };

    const handleValidation = () => {
        let isValid = true;
        let errors = {};
    
        console.log("formData before validation:", formData);
    
        // Check if 'type' exists and has 'id'
        if (!formData.type || !formData.type.id) {
            isValid = false;
            errors.type = "Please enter a Device type";
        }
    
        // Validate required fields, excluding camera-related ones
        const requiredFields = [
            "name", 
            "deviceMake", 
            "serialNumber", 
            "dateInstalled", 
            "macAddress", 
            "ipAddress", 
            "topicName"
        ];
    
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                isValid = false;
                errors[field] = `Please enter ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`;
            }
        });
    
        // Validate dateInstalled (ensure it's a valid date)
        if (!formData.dateInstalled || !formData.dateInstalled.isValid()) {
            isValid = false;
            errors.dateInstalled = "Please enter a valid installation date";
        }
    
        // Check if the device type is 'Camera'
        if (formData.type?.name === "Camera") {
            console.log("Validating Camera-specific fields:", formData.type?.name);
    
            // Validate cameraType
            if (!formData.cameraType) {
                isValid = false;
                errors.cameraType = "Please enter a Camera type";
            }
    
            // Validate streamUrl
            if (!formData.streamUrl || !/^https?:\/\/.+/.test(formData.streamUrl)) {
                isValid = false;
                errors.streamUrl = "Please enter a valid stream URL";
            }
    
            // Validate videoLength (ensure it's a positive number)
            if (
                formData.videoLength === undefined ||
                formData.videoLength === null ||
                isNaN(formData.videoLength) ||
                Number(formData.videoLength) <= 0
            ) {
                isValid = false;
                errors.videoLength = "Please enter a valid video length (positive number)";
            }
        }
    
        // Validate ipAddress (IPv4 or IPv6)
        const ipv4Regex =
            /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
        const ipv6Regex =
            /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9]))$/;
    
        if (!formData.ipAddress || !(ipv4Regex.test(formData.ipAddress) || ipv6Regex.test(formData.ipAddress))) {
            isValid = false;
            errors.ipAddress = "Please enter a valid IPv4 or IPv6 address";
        }
    
        // Validate MAC address
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        if (!formData.macAddress || !macRegex.test(formData.macAddress)) {
            isValid = false;
            errors.macAddress = "Please enter a valid MAC address";
        }
    
        // Log final validation errors
        console.log("Final Validation Errors:", errors);
        setFormError(errors);
    
        return isValid;
    };
    
    // Submit handler
    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const formDataToSend = {
                name: formData.name,
                cssXCoordinate: 0,
                cssYCoordinate: 0,
                type: formData.type,
                cameraType: formData.cameraType,
                streamUrl: formData.streamUrl,
                videoLength: formData.videoLength,
                deviceLocationName: "", 
                deviceLocationId: null,
                triggeredByDeviceId: [0],
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: decodedToken.userGuid,
                modifiedBy: decodedToken.userGuid,
                triggeredBy: "0",
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: formData.topicName,
                floorId: null
            };
            const url = `https://lockdownexperts.biz/api/v1/device/addDevice`;
            // const response = await axios.get(url);
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json',  // Optional: Ensure content type is JSON
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 201) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error making request:", error);
            // setFormError("Invalid credentials");
            // setShowError(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("")
        setSuccessMessage(false)
        setTestConnectionStatus("")
        setFormData({
            name: '',
            type: {},
            deviceMake: '',
            cameraType: '',
            streamUrl: '',
            videoLength: '',
            status: 0,
            serialNumber: '',
            dateInstalled: null,
            macAddress: '',
            ipAddress: '',
            topicName: ''
        });
    };

    const handleEdit = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const formDataToSend = {
                id: editData.id,
                name: formData.name,
                type: formData.type,
                cssXCoordinate: 0,
                cssYCoordinate: 0,
                cameraType: formData.cameraType,
                streamUrl: formData.streamUrl,
                videoLength: Number(formData.videoLength),
                deviceLocationName: "string", // Placeholder
                deviceLocationId: editData.deviceLocationId,
                triggeredByDeviceId: [0],     // Placeholder
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: editData.createdBy,
                modifiedBy:decodedToken.userGuid,
                triggeredBy: "0",        // Placeholder
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: formData.topicName,
                // floorId: editData.floorId
            };

            const response = await axios.put(`https://lockdownexperts.biz/api/v1/device/update/${editData.id}`, formDataToSend, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, }
            });

            if (response.status === 200) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        if (actionButton === 'add') {
            console.error("Error updating device:");
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };
    // Listing APi 
    const fetchDeviceType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://lockdownexperts.biz/api/v1/deviceType/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setDeviceType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    // Listing APi 
    const fetchStatusType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://lockdownexperts.biz/api/v1/states/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setStatusType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        // Call the async function
        fetchDeviceType();
        fetchStatusType();
    }, []);

    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            const dateInstalledValue = editData.installed
                ? dayjs(editData.installed, 'YYYY-MM-DD')
                : null;
            // If editing, set form data to the existing device data
            setFormData({
                name: editData.name,
                type: editData.type,
                deviceMake: editData.deviceMake,
                status: editData.status,
                serialNumber: editData.serialNumber,
                dateInstalled: dateInstalledValue,  // Ensure it's a dayjs object
                macAddress: editData.macAddress,
                ipAddress: editData.ipAddress,
                topicName: editData.topicName,
                cameraType: editData.cameraType,
                streamUrl: editData.streamUrl,
                videoLength: editData.videoLength
            });
        } else {
            setFormData({
                name: "",
                deviceMake: "",
                status: 0,
                type: {},
                deviceMake: '',
                cameraType: '',
                streamUrl: '',
                serialNumber: "",
                dateInstalled: null,
                macAddress: "",
                ipAddress: "",
                topicName: ""
            });
        }
    }, [actionButton, editData]);

    const handleTestConnection = async (e) => {
        e.preventDefault();
    
        const url = streamUrlNew; // Replace with your endpoint
        console.log(url, "Testing connection...");
    
        // Validate streamUrl
        if (!url || !/^https?:\/\/.+/.test(url)) {
            setTestConnectionStatus("error");
            console.error("Invalid URL: Please enter a valid stream URL.");
            return;
        }
    
        // Proceed with the connection test if URL is valid
        try {
            const response = await fetch(url, { method: "HEAD" });
            if (response.ok) {
                setTestConnectionStatus("success");
                console.log("Connection successful.");
            } else {
                setTestConnectionStatus("error");
                console.error("Connection failed:", response.statusText);
            }
        } catch (error) {
            setTestConnectionStatus("error");
            console.error("Error occurred during the connection test:", error);
        }
    };
    
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit Device Management" : "Add Device Management"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle  addDeviceMiddle login-form">
                    {!successMessage ?
                        <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                            <Row>
                                {field.map((data, index) => {
                                    return (
                                        <>

                                            {data.type == "Camera" ? (
                                                (renderDeviceType.name == "Camera" && renderDeviceType.name !== "") || formData.type ?.id == 1 ?
                                                    <Col xl={6} lg={6} md={6} sm={6} className="reletiveWrapper">
                                                        <TextField
                                                            key={index}
                                                            id={data.id}
                                                            name={data.name}
                                                            label={data.label}
                                                            variant="outlined"
                                                            onChange={handleInputChange}
                                                            value={formData[data.name] || ''}
                                                            error={!!formError[data.name]}
                                                            className="innerFields addDevices"
                                                            helperText={formError[data.name] || data.helperText}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                style: {
                                                                    transform: 'inherit',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: { borderRadius: '4px' },
                                                            }}
                                                        />
                                                        {data.id == "streamUrl" ? <button className={`testConnection ${testConnectionStatus == "success" ? "scs" : testConnectionStatus == "error" ? "err " : " "}`} onClick={(e) => handleTestConnection(e)} >Test Connection</button> : ""}
                                                    </Col>
                                                    : ""
                                        ) : data.type == "input" ?
                                                    <Col xl={6} lg={6} md={6} sm={6}>
                                                        <TextField
                                                            key={index}
                                                            id={data.id}
                                                            name={data.name}
                                                            label={data.label}
                                                            variant="outlined"
                                                            onChange={handleInputChange}
                                                            value={formData[data.name] || ''}
                                                            error={!!formError[data.name]}
                                                            className="innerFields addDevices"
                                                            helperText={formError[data.name] || data.helperText}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                style: {
                                                                    transform: 'inherit',
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: { borderRadius: '4px' },
                                                            }}
                                                        />
                                                    </Col>
                                                    : data.type == "select" ?
                                                        <Col xl={6} lg={6} md={6} sm={6}>
                                                            <FormControl fullWidth className="innerFields addDevices">
                                                                <InputLabel variant="standard" htmlFor={data.id} shrink={false}>
                                                                    {data.label}
                                                                </InputLabel>
                                                                <NativeSelect
                                                                    id={data.id}
                                                                    name={data.name}
                                                                    value={data.name === "status" ? formData.status || '' : formData.type ?.id || ''}
                                                                    onChange={(e) =>
                                                                        data.name === "status" ? handleStatusTypeChange(e) : handleDeviceTypeChange(e)
                                                                    }
                                                                >
                                                                    <option value="" disabled>
                                                                        Select an option
                                                  </option>

                                                                    {/* Sorting options before rendering */}
                                                                    {(data.name === "status" ? statusType : deviceType)
                                                                        ?.sort((a, b) => {
                                                                            // Sorting by name alphabetically, or you can change it to a different property or logic
                                                                            return a.name.localeCompare(b.name);
                                                                        })
                                                                            .map((item) => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            ))}
                                                                </NativeSelect>
                                                                <FormHelperText>{formError.type}</FormHelperText>
                                                            </FormControl>
                                                        </Col>
                                                        : <Col xl={6} lg={6} md={6} sm={6}>
                                                            <div className="innerFields addDevices">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label={data.label}
                                                                        value={formData.dateInstalled}
                                                                        onChange={handleDateChange}
                                                                        renderInput={(params) => (
                                                                            <FormControl fullWidth variant="outlined">
                                                                                <InputLabel
                                                                                    htmlFor={params.inputProps.id}
                                                                                    shrink={false}
                                                                                >
                                                                                    {data.label}
                                                                                </InputLabel>
                                                                                <TextField
                                                                                    {...params}
                                                                                    // error={!!formError.dateInstalled}
                                                                                    fullWidth
                                                                                    // helperText={formError.dateInstalled || data.helperText}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            error: !!formError.dateInstalled, // Bolean
                                                                                            helperText: formError.dateInstalled, // String
                                                                                        },
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    InputLabelProps={{
                                                                                        shrink: false,
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        )}
                                                                    />
                                                                    {formError.dateInstalled && <p className="customerror">Please enter installation date</p>}
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Col>
                                            }

                                        </>
                                    )
                                })}
                            </Row>
                        </Box>
                        : <div className="emailsent-wrapper manageUser_details">
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>Device has {actionButton == "add" ? "created" : "updated"} successfully.</p>
                        </div>
                    }
                </div>
                {/* <div className="error_wraper">
                    <img src={errorSign} alt="img" />
                    {formError}
                </div> */}
                <div className="Modal_footer adddeviceFooter">
                    {/* Buttons */}
                    {!successMessage && <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" className="cancelBtn" onClick={(e) => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={(e) => handleSubmit(e)}>
                            Save
                        </Button>
                    </Box>}
                </div>
            </Box>
        </Modal>

    );
};

export default AddEditDevice;
